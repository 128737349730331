import { Component, Input, OnInit } from '@angular/core';
import { AnimationController, PopoverController } from '@ionic/angular';
import { UserService } from 'src/app/core/user.service';
import { AppService } from '../app.service';
import { CartService } from '../cart.service';
import { MenuPopoverComponent } from '../menu-popover/menu-popover.component';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.scss'],
})
export class DesktopHeaderComponent implements OnInit {
  @Input() disabled: boolean = false;

  constructor(
    public cartService: CartService,
    public userService: UserService,
    public popoverController: PopoverController,
    public appService: AppService,
    private animationCtrl: AnimationController,
    private menuCtrl: MenuController
  ) {
  }

  ngOnInit() {
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: MenuPopoverComponent,
      cssClass: 'popover-menu',
      event: ev,
      translucent: true,
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
  }

}
