import { pickBy } from 'lodash';
import { Category } from './category.model';
import { Label } from './label.model';
import { Finishing, Price, PrintingGroup } from './models';
import { PrintVariant } from './printProduct.model';

// short product reference
export interface Product extends Product.Base {
  highestDiscount?: number;
  cheapestVariant?: any;
  _version: number //Version del modelo de Producto
  variantId?: string;
  type: ProductType;
  listOrder: number; //Orden que aparecerá en la lista
  title: string;
  description: string;
  descriptionLines?: Array<object>;
  mediaFiles: string[];
  printingGroup?: PrintingGroup
  internalId?: string
  extra?: Object[] | any; //Lo usa el bono
  folderName?: string;
  slug: string;
  seo: {
    title: string;
    description: string;
  }
  featured: boolean;
  categories: Category[]; // o Category sin array?
  labels: Label[];
  status: Product.Status;
  options: Array<Product.Option>;
  optionsValue?: Array<String>;
  properties?: object;
  variantCount: number;
  variants?: Array<Product.Variant> | Array<PrintVariant>;
  vendor: Product.VendorRef;
  createdAt?: Date;
  updatedAt?: Date;
  packProducts?: Array<any>;

  //New.............._
  exclusive: boolean;
  relevance: number;
  presale: boolean;
  categoryScore: number;


  //solo para printProducts
  weights?: Array<any>;
  royalty?: number;
  //solo para printproduct 0
  bindingData?: any;
  bindingSheetLimit?: number;
  ringColorPrice?: RingColorPrice;
  hardCoverPrice?: HardCoverPrice;
  extraPrices?: ExtraPrices;
  ringColors?: Array<RinColor>;
  hardCovers?: Array<HardCover>;
  ///TODO pomerlo en admin
  previewPages?: number; // write: pages from original document to use in preview. 0 means: whole document. defaults to 4.
  previewUrl?: string, // read-only: url with the pre-generated preview
  subType?: ProductSubType;
  customElementsSelected?: Object;
  customCombination?: string;
  customType?: string;

}

export interface RinColor {
  name: string;
  color: string,
  id: string
}

export interface HardCover {
  name: string,
  color: string,
  id: string,
  internalId?: string
}

export interface RingColorPrice {
  Transparente: number,  // extra price for color: 0€
  Default: number     // extra price for other colors: xx€
}

export interface HardCoverPrice {
  default: number;
  noDefault: number;
  exclusive: number;
}


export interface ExtraPrices {
  blankSheetPercentageCalc: number;
  finishing: {
    [Finishing.BINDING]: ExtraFinishingOption,
    [Finishing.STAPLED]: ExtraFinishingOption,
    [Finishing.LAMINATEDA4]: ExtraFinishingOption,
    [Finishing.LAMINATEDA3]: ExtraFinishingOption,
    [Finishing.PERFORATED2]: ExtraFinishingOption,
    [Finishing.PERFORATED4]: ExtraFinishingOption,
    [Finishing.PERFORATEDBIND]: ExtraFinishingOption,
    [Finishing.NONE]: ExtraFinishingOption
  },
  coverColor: {
    true: ExtraBase;
    false: ExtraBase;
  },
  docColor: {
    true: ExtraBase;
    false: ExtraBase;
  },
  skipFitToPage: {
    true: ExtraBase;
  },
}

export interface ExtraFinishingOption extends ExtraBase {
  limit: number,  // límite de folios
  weight: number
}

export interface ExtraBase {
  price: number, // precio adicional al base COLOR (por página impresa: 1 sola, esa portada)
  description: string,
}

export enum ProductType { PRINT = 'print', STORE = 'store', WALLETBONUS = 'walletbonus', GENERIC = 'generic' }

export enum ProductSubType {
  STANDARD = 'standard',
  CUSTOM = 'custom',
  PACK = 'pack',
}

export enum ProductCustomType {
  NOTEBOOK = 'notebook',
  RESOURCE = 'resource'
}

export enum ProductCustomizableElements {
  PAPER_FORMAT = 'paperFormat',
  SHEET_NUMBER = 'sheetNumber',
  RINGS = 'rings',
  COVER_FRONT = 'coverFront',
  COVER_BACK = 'coverBack',
  PDF = 'pdf',
}

/**
 variantes

 //En catálogo se muestra el de menor precio
 //En la lista total de inventario de todos
 //Para sabe si está agotado se debe comtemplar todas las variaciones
 */

export namespace Product {
  export type Status = 'active' | 'archived' | 'draft';

  export interface Option {
    name: String;
    values: Array<String>;
  }

  export interface Variant extends Product.Base {
    optionsValue: Array<String>;
  }

  export interface Base {
    id: string;
    unitPrice: Price; // page price, binding price, product price
    discount: number;
    hideDiscount: boolean;
    cost: Price;
    stock: number;
    soldQty: number;
    allowSaleNoStock: boolean;
    sku: string;
    requiresShipping: boolean;
    freeShipping?: boolean;
    weight: number;
    pictureUrl?: string;
    pictureSecondaryUrl?: string;
    pictureId?: string;
    pictureSecondaryId?: string;
    title?: string;
    copyPrice?: number;
  }

  export const newProductBase = (product: Product): Base => {
    const {
      id,
      unitPrice,
      discount,
      hideDiscount,
      cost,
      stock,
      soldQty,
      allowSaleNoStock,
      sku,
      requiresShipping,
      weight,
      pictureUrl,
      pictureId
    } = product;
    return {
      id,
      unitPrice,
      discount,
      hideDiscount,
      cost,
      stock,
      soldQty,
      allowSaleNoStock,
      sku,
      requiresShipping,
      weight,
      pictureUrl,
      pictureId
    };
  };

  export interface VendorRef {
    id: string,
    name: string;
    thumbnail: string;
    slug?: string;
  }

  // short product reference
  export interface _Product extends Product.Base {
    id: string; // reference to Product
    categories: Category[];
    variantId?: string;
    type: ProductType;
    subType?: ProductSubType;
    customElementsSelected?: any;
    customCombination?: string;
    title: string;
    description: string;
    descriptionLines?: Array<object>;
    unitPrice: Price; // page price, binding price, product price
    pictureUrl?: string;
    printingGroup?: PrintingGroup;
    // extra?:Object[];
    weight: number;
    extra?: any;
    vendor: Product.VendorRef;
    slug: string;
    listOrder?: number;
    optionsValue?: Array<String>;
    properties?: object;
    royalty?: number;
    allowSaleNoStock: boolean;
    stock: number;
    presale?: boolean;
    customType?: string;
    folderName?: string;
  }

  export const new_Product = (product: Product): _Product => {
    const {
      id,
      variantId,
      type,
      subType,
      customElementsSelected,
      customCombination,
      customType,
      title,
      description,
      descriptionLines,
      unitPrice,
      pictureUrl,
      pictureId,
      printingGroup,
      weight,
      extra,
      folderName,
      listOrder,
      vendor,
      slug,
      optionsValue,
      properties,
      royalty,
      allowSaleNoStock,
      stock,
      sku,
      packProducts,
      presale,
      freeShipping
    } = product;
    return pickBy({
      id,
      variantId,
      type,
      subType,
      customElementsSelected,
      customCombination,
      customType,
      title,
      description,
      descriptionLines,
      unitPrice,
      pictureUrl,
      pictureId,
      printingGroup,
      weight,
      extra,
      folderName,
      listOrder,
      vendor,
      slug,
      optionsValue,
      properties,
      royalty,
      allowSaleNoStock,
      stock,
      sku,
      packProducts,
      presale,
      freeShipping
    }, (value, key) => value !== undefined) as _Product;
  };


  /*export const new_Product = (product: Product._Product): _Product => {
    const {
      id,
      variantId,
      type,
      title,
      description,
      descriptionLines,
      unitPrice,
      pictureUrl,
      pictureId,
      printingGroup,
      weight,
      extra,
      folderName,
      listOrder,
      vendor,
      slug,
      optionsValue,
      properties,
      royalty,
      allowSaleNoStock,
      stock,
      sku,
      packProducts,
      presale,
      freeShipping
    } = product;
    return pickBy({
      id,
      variantId,
      type,
      title,
      description,
      descriptionLines,
      unitPrice,
      pictureUrl,
      pictureId,
      printingGroup,
      weight,
      extra,
      folderName,
      listOrder,
      vendor,
      slug,
      optionsValue,
      properties,
      royalty,
      allowSaleNoStock,
      stock,
      sku,
      packProducts,
      presale,
      freeShipping
    }, (value, key) => value !== undefined) as _Product;
  };*/


  /**
   * Genera todas las variaciones posibles según las opciones.
   * @param options : Variation Options
   * @param variants : Old Variations
   * @returns variants new Variants
   */
  export const newVariants = (
    options: Array<Product.Option>,
    oldVariants: Array<Product.Variant> = [],
    defaultValue: Partial<Product.Variant>
  ): Array<Product.Variant> => {
    const variants: Array<Product.Variant> = _genVariants(options, oldVariants, defaultValue);

    return variants;
  };
  //Verifica si el producto seleccionado está agotado
  export const isSoldOut = (product: Base) => !product.allowSaleNoStock && product.stock <= 0;
  //Verifica si todo el  producto contando las variaciones está agotado
  export const isAllProductSoldOut = (product: Product) => {
    const variants = product.variants as Array<Product.Variant>;
    return !product.variantCount
      ? isSoldOut(product)
      : variants.every(isSoldOut);
  };
  export const saledPrice = (product: Base) => product?.discount
    ? { amt: product?.unitPrice.amt * (1 - product?.discount / 100), cur: product?.unitPrice.cur }
    : product?.unitPrice;
  export const isCustom = (product: Product) => product.subType === ProductSubType.CUSTOM;
  export const relevance = (product: Product) => _calcRelevance(product);
  export const cheapestVariant = (variants: Array<Variant>) => {
    return variants.length ? (variants.reduce((v, variant) => {
      return v && (saledPrice(variant).amt > saledPrice(v).amt) ? v : variant;
    })) : null;
  };
  export const highestDiscount = (variants: Array<Variant>) => {
    return variants.length ? (variants.reduce((maxDiscount, currentVariant) => {
      return currentVariant.discount > maxDiscount ? currentVariant.discount : maxDiscount;
    }, 0)) : null;
  };
}

///////////Internal Functions/////////////
function _genVariants(options, _var = [], defaultValue: Partial<Product.Variant>) {
  const variants = _genNewVariants([], [], options, defaultValue) as Product.Variant[];
  return variants;
}

function _genNewVariants(key: string[], acc: Array<Product.Variant>, options: Array<Product.Option>, defaultValue?: Partial<Product.Variant>) {
  const _options = [...options];
  const option = _options.shift();

  if (options.length && option.values?.length) {
    option.values.forEach((v: string) => {
      _genNewVariants([...key, v], acc, _options, defaultValue);
    });
    return acc;
  } else {
    acc.push(_newVariant(key, defaultValue));
    return key;
  }
}

function _newVariant(optionsValue: Array<String>, defaultValue?: Partial<Product.Variant>) {
  return {
    id: null,
    optionsValue,
    unitPrice: { amt: 0, cur: 'EUR' },
    discount: 0,
    hideDiscount: false,
    cost: { amt: 0, cur: 'EUR' },
    stock: 0,
    soldQty: 0,
    allowSaleNoStock: false,
    sku: '',
    requiresShipping: true,
    weight: 0,
    pictureUrl: null,
    pictureId: null,
    ...defaultValue
  };
}

function _isAvailable(product: Product) {
  return product.allowSaleNoStock || product.stock > 0;
}

const AVAILABLE_FLAG = 0x40;
const EXCLUSIVE_FLAG = 0x10;
const FEATURED_FLAG = 0X04;

function _calcRelevance(product: Product) {
  let relevance = 0;
  relevance = _isAvailable(product) ? relevance | AVAILABLE_FLAG : relevance;
  relevance = product.exclusive ? relevance | EXCLUSIVE_FLAG : relevance;
  relevance = product.featured ? relevance | FEATURED_FLAG : relevance;
  return relevance;
}


