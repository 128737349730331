// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-searchbar {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-bottom: 18px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlYXJjaC1iYXIuY29tcG9uZW50LnNjc3MiLCIuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXENvcHlmbHklMjAyXFxEZXNrdG9wXFxEZXNhcnJvbGxvXFxjb3Bpc3RlcmlhLWFwcC1pb25pY1xcc3JjXFxhcHBcXGNvbXBvbmVudHNcXHNlYXJjaC1iYXJcXHNlYXJjaC1iYXIuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSw2RUFBQTtFQUNBLG1CQUFBO0FDQ0YiLCJmaWxlIjoic2VhcmNoLWJhci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImlvbi1zZWFyY2hiYXIge1xyXG4gIHRyYW5zaXRpb246IGJhY2tncm91bmQgMC4zcywgYm9yZGVyIDAuM3MsIGJvcmRlci1yYWRpdXMgMC4zcywgYm94LXNoYWRvdyAwLjNzO1xyXG4gIG1hcmdpbi1ib3R0b206IDE4cHg7XHJcbn1cclxuIiwiaW9uLXNlYXJjaGJhciB7XG4gIHRyYW5zaXRpb246IGJhY2tncm91bmQgMC4zcywgYm9yZGVyIDAuM3MsIGJvcmRlci1yYWRpdXMgMC4zcywgYm94LXNoYWRvdyAwLjNzO1xuICBtYXJnaW4tYm90dG9tOiAxOHB4O1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/search-bar/search-bar.component.scss","webpack://./../../../../Copyfly%202/Desktop/Desarrollo/copisteria-app-ionic/src/app/components/search-bar/search-bar.component.scss"],"names":[],"mappings":"AAAA;EACE,6EAAA;EACA,mBAAA;ACCF;ADCA,wzBAAwzB","sourcesContent":["ion-searchbar {\r\n  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;\r\n  margin-bottom: 18px;\r\n}\r\n","ion-searchbar {\n  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;\n  margin-bottom: 18px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
