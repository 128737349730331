// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  height: 100%;
  padding: 5px;
}

ion-button {
  height: 32px;
}
ion-button::part(native) {
  margin: 0 !important;
  padding: 0 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNsb3NlLW1vZGFsLWJ1dHRvbi5jb21wb25lbnQuc2NzcyIsIi4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcQ29weWZseSUyMDJcXERlc2t0b3BcXERlc2Fycm9sbG9cXGNvcGlzdGVyaWEtYXBwLWlvbmljXFxzcmNcXGFwcFxcY29tcG9uZW50c1xcY2xvc2UtbW9kYWwtYnV0dG9uXFxjbG9zZS1tb2RhbC1idXR0b24uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxZQUFBO0VBQ0EsWUFBQTtBQ0NGOztBREVBO0VBS0UsWUFBQTtBQ0hGO0FEREU7RUFDRSxvQkFBQTtFQUNBLHFCQUFBO0FDR0oiLCJmaWxlIjoiY2xvc2UtbW9kYWwtYnV0dG9uLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaW1nIHtcclxuICBoZWlnaHQ6IDEwMCU7XHJcbiAgcGFkZGluZzogNXB4O1xyXG59XHJcblxyXG5pb24tYnV0dG9uIHtcclxuICAmOjpwYXJ0KG5hdGl2ZSkge1xyXG4gICAgbWFyZ2luOiAwICFpbXBvcnRhbnQ7XHJcbiAgICBwYWRkaW5nOiAwICFpbXBvcnRhbnQ7XHJcbiAgfVxyXG4gIGhlaWdodDogMzJweDs7XHJcbn1cclxuIiwiaW1nIHtcbiAgaGVpZ2h0OiAxMDAlO1xuICBwYWRkaW5nOiA1cHg7XG59XG5cbmlvbi1idXR0b24ge1xuICBoZWlnaHQ6IDMycHg7XG59XG5pb24tYnV0dG9uOjpwYXJ0KG5hdGl2ZSkge1xuICBtYXJnaW46IDAgIWltcG9ydGFudDtcbiAgcGFkZGluZzogMCAhaW1wb3J0YW50O1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/close-modal-button/close-modal-button.component.scss","webpack://./../../../../Copyfly%202/Desktop/Desarrollo/copisteria-app-ionic/src/app/components/close-modal-button/close-modal-button.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;ACCF;;ADEA;EAKE,YAAA;ACHF;ADDE;EACE,oBAAA;EACA,qBAAA;ACGJ;ADCA,4hCAA4hC","sourcesContent":["img {\r\n  height: 100%;\r\n  padding: 5px;\r\n}\r\n\r\nion-button {\r\n  &::part(native) {\r\n    margin: 0 !important;\r\n    padding: 0 !important;\r\n  }\r\n  height: 32px;;\r\n}\r\n","img {\n  height: 100%;\n  padding: 5px;\n}\n\nion-button {\n  height: 32px;\n}\nion-button::part(native) {\n  margin: 0 !important;\n  padding: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
