import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Product } from "../../models/product.model";
import { SettingService } from "../../shared/setting.service";
import { PrintProductStateService } from "../print-product-state.service";
import { CartService } from "../../shared/cart.service";
import { Finishing, ProductType, Settings } from "../../models/models";

@Component({
  selector: 'app-binding-options',
  templateUrl: './binding-options.component.html',
  styleUrls: ['./binding-options.component.scss']
})
export class BindingOptionsComponent implements OnInit, OnChanges {
  @Input() settings;
  @Input() qty;
  @Input() printProduct;
  @Input() file = null;
  @Output() optionSelect = new EventEmitter<any>();
  options = ['ringColor', 'hardCoverFront', 'hardCoverBack'];
  optionSelectd = 0;
  settingOption = this.options[this.optionSelectd];
  bindingStock;
  printingGroup;

  constructor(private settingService: SettingService,
              private printProductStateService: PrintProductStateService,
              private cartService: CartService) {
  }

  ngOnInit() {
    this.printProductStateService.printProduct$.subscribe(printProduct => {
      this.printingGroup = printProduct.product.printingGroup;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.optionSelectd = 0;
    this.settingOption = this.options[this.optionSelectd];
  }

  onSelectOption(i, soldOut = false) {
    if (soldOut) {
      return;
    }
    const prev = this.settings[this.settingOption].selected;
    this.settings[this.settingOption].selected = i + 1;
    this.optionSelect.emit({ setting: this.settingOption, option: this.settings[this.settingOption].selected, prev });
  }


  onSegmentChange(event) {
    this.settingOption = event.detail.value;
    this.optionSelect.emit({ setting: 'segment' });
  }

  isSomeExclusive(options) {
    return options.some(op => op.value.exclusive);
  }

  isSoldOut(option, selected) {
    const _printSettings = this.printingGroup.printSettings;
    const isHardCover = ['hardCoverFront', 'hardCoverBack'].includes(this.settingOption);
    const isRingColor = this.settingOption === 'ringColor';
    const stockArray = isHardCover ? this.cartService.bindingStock.covers : this.cartService.bindingStock?.rings;
    const cartBindingUses = this.cartService.cartBindingStockUses;
    let itemStock;
    let itemCartUses;
    let qtyToAdd = 1;    //qty to add
    let soldOut = false;
    if (_printSettings.grouped) {
      qtyToAdd = selected ? 0 : 1 * this.qty
    } else {
      if (this.file) {
        qtyToAdd = selected ? 0 : 1 * this.qty
      } else {
        qtyToAdd = selected ? 0 : this.printingGroup.files.length * this.qty
      }
    }

    if (_printSettings.grouped) {
      itemStock = stockArray.find(el => el.color === option.value.id && el[isHardCover ? 'pageSize' : 'diameter'] == (isHardCover ? this.printingGroup.printSettings.pageSize : this.printingGroup.binding.ringDiameter));
      if (isRingColor) {
        itemCartUses = cartBindingUses.rings.find(el => el.color === option.value.id && el.diameter == this.printingGroup.binding.ringDiameter)?.uses ?? 0;
      }
      if (isHardCover) {
        itemCartUses = cartBindingUses.covers.find(el => el.color === option.value.id && el.pageSize == this.printingGroup.printSettings.pageSize)?.uses ?? 0;
      }
    } else {
      if (this.file) {
        itemStock = stockArray.find(el => el.color === option.value.id && el[isHardCover ? 'pageSize' : 'diameter'] == (isHardCover ? this.printingGroup.printSettings.pageSize : this.file.binding.ringDiameter));
        if (isRingColor) {
          itemCartUses = cartBindingUses.rings.find(el => el.color === option.value.id && el.diameter == this.file.binding.ringDiameter)?.uses ?? 0;
        }
        if (isHardCover) {
          itemCartUses = cartBindingUses.covers.find(el => el.color === option.value.id && el.pageSize == this.printingGroup.printSettings.pageSize)?.uses ?? 0;
        }
      } else {
        this.printingGroup.files.forEach(file => {
          itemStock = stockArray.find(el => el.color === option.value.id && el[isHardCover ? 'pageSize' : 'diameter'] == (isHardCover ? this.printingGroup.printSettings.pageSize : file.binding?.ringDiameter));
          if (isRingColor) {
            itemCartUses = cartBindingUses.rings.find(el => el.color === option.value.id && el.diameter == file.binding?.ringDiameter)?.uses ?? 0;
          }
          if (isHardCover) {
            itemCartUses = cartBindingUses.covers.find(el => el.color === option.value.id && el.pageSize == this.printingGroup.printSettings.pageSize)?.uses ?? 0;
          }
          if (itemStock && itemStock.stock - itemCartUses - qtyToAdd < 0 && !selected) {
            soldOut = true;
          }
        })
      }
    }
    if (!_printSettings.grouped && !this.file) {
      return soldOut
    } else {
      return itemStock ? itemStock.stock - itemCartUses - qtyToAdd < 0 && !selected : option.value.soldOut;
    }
  }

  someCoverLaminated() {
    const result = this.printProduct.product.printingGroup.files.some(f => f.coverLaminated);
    if (result && this.settingOption === 'hardCoverFront') {
      this.settingOption = 'ringColor';
      this.optionSelect.emit({ setting: 'segment' });
    }
    return result
  }

  getOptionsId() {
    return this.settingOption === 'ringColor' ? 'rings' : 'covers';
  }

  protected readonly Product = Product;
  protected readonly Settings = Settings;
}
