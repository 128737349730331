import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { EmmiterMessage, OutputMessage } from 'src/app/models/models';
import { ProductList } from 'src/app/models/product-ui.model';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export default class ProductCardComponent implements OnInit, OnChanges, EmmiterMessage {
  @Input() product: ProductList;
  @Input() lateralList = false;
  @Input() smallLabels = false;
  @Output() action = new EventEmitter<OutputMessage>();

  elementHover = false;

  constructor() {
  }


  ngOnInit(): void {
  }

  ngOnChanges(): void {
    // console.log("Producto: ",this.product);
  }

  emitAction(type: string, value?: any): void {
    this.action.emit(new OutputMessage(type, value));
  }

  setElementHover(status) {
    this.elementHover = status;
  }
}
