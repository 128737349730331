import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ProductList } from 'src/app/models/product-ui.model';
import { Product } from "../../../../models/product.model";
import saledPrice = Product.saledPrice;

@Component({
  selector: 'app-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss']
})
export default class ProductPriceComponent implements OnInit, OnChanges {

  @Input() product: ProductList
  public isDiscount: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
    this.refreshDiscount();
  }
  ngOnChanges(){
    this.refreshDiscount();
  }

  /** Updates the discount  */
  refreshDiscount() {
    this.isDiscount = this.product.discount > 0;
  }

  getPrice() {
    if (this.product.hasVariant) {
      return {
        currentPrice: saledPrice(this.product.cheapestVariant)?.amt,
        previousPrice: this.product.cheapestVariant?.unitPrice.amt
      }
    } else {
      return {
        currentPrice: saledPrice(this.product)?.amt,
        previousPrice: this.product.unitPrice.amt
      }
    }

  }
}
