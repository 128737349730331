import { Component, Input, OnInit } from '@angular/core';
import { _PrintFile, Finishing } from "../../models/models";
import { ModalController } from "@ionic/angular";
import { FileService } from "../../shared/file.service";
import { CoverSettingsComponent } from "../cover-settings/cover-settings.component";

@Component({
  selector: 'app-grouped-binding-individual-settings',
  templateUrl: './grouped-binding-individual-settings.component.html',
  styleUrls: ['./grouped-binding-individual-settings.component.scss'],
})
export class GroupedBindingIndividualSettingsComponent implements OnInit {
  pFile: _PrintFile;
  printingGroup;
  blankSheetsBefore;
  blankSheetsAfter;
  alias;
  docColor;
  coverColor;
  coverLaminated;
  fileSettings;
  index;

  constructor(private modalController: ModalController, public fileService: FileService) {
  }

  ngOnInit() {
    this.docColor = this.docColor ? 'color' : 'bn'
    this.coverLaminated = !!this.coverLaminated
    this.coverColor = !!this.coverColor
  }

  changeBlankSheetsQty(event, position) {
    const qty = event.qty;
    position === 'before' ? this.blankSheetsBefore = qty : this.blankSheetsAfter = qty;
  }

  protected readonly Finishing = Finishing;

  cancel() {
    this.modalController.dismiss();
  }

  submit() {
    const data = {
      alias: this.alias,
      blankSheetsBefore: this.blankSheetsBefore,
      blankSheetsAfter: this.blankSheetsAfter,
      docColor: this.docColor,
      coverColor: this.coverColor,
      coverLaminated: this.coverLaminated
    }
    this.modalController.dismiss(data);
  }

  get currentCoverTypeData() {
    return this.fileService.getCoverSettings().find(cs => cs.value.id === this.pFile.coverType?.id);
  }

  async openCoverSettings() {
    const modal = await this.modalController.create({
      component: CoverSettingsComponent,
      componentProps: {
        fileSettings: this.fileSettings,
        currentCoverType: this.pFile.coverType
      },
      cssClass: "auto-height",
    });
    await modal.present();

    modal.onDidDismiss().then((resp) => {
      const { data } = resp;
      if (data?.coverType) {
        this.pFile.coverType = data.coverType;
        this.fileService.coverTypeChangedEvent.emit(this.index)
      }
    });
  }
}
